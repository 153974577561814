import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './ToolsPage.css'; // Import the CSS for styling
import ChatGPT from '../../component/chatgpt';
import tickIcon from '../../assets/tick-icon.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { signOut } from 'firebase/auth';
import { auth, db } from '../../firebaseConfig'; // Firebase auth & firestore config
import { getDocs, query, collection, where, doc, getDoc } from 'firebase/firestore';
import backIcon from '../../assets/back.png'

const tools = [

  { name: 'PDF OCR', icon: require('../../assets/tools/5.png'), path: '/pdf-ocr', dbName: 'PDF OCR' },
  // { name: 'Translate Pdf', icon: require('../../assets/tools/24.png'), path: '/translate-pdf', dbName: 'Translate PDF' },
  { name: 'Speech To Translation', icon: require('../../assets/tools/26.png'), path: '/speech-translate', dbName: 'Speech To Translation', },
  { name: 'Word To PDF', icon: require('../../assets/tools/4.png'), path: '/word-pdf', dbName: 'Word to PDF' },
  { name: 'PDF to Word', icon: require('../../assets/tools/8.png'), path: '/pdf-word', dbName: 'PDF to Word' },
  { name: 'Office to PDF', icon: require('../../assets/tools/8.png'), path: '/office-pdf', dbName: 'Office to PDF' },
  { name: 'Protect PDF', icon: require('../../assets/tools/14.png'), path: '/protect-pdf', dbName: 'Protect PDF' },
  { name: 'Unlock PDF', icon: require('../../assets/tools/10.png'), path: '/unlock-pdf', dbName: 'Unlock PDF' },
  { name: 'PDF to PDFA-2B', icon: require('../../assets/tools/15.png'), path: '/pdf-pdfa', dbName: 'PDF to PDF/A' },
  { name: 'Validate PDF/A', icon: require('../../assets/tools/16.png'), path: '/validate-pdfa', dbName: 'Validate PDF/A' },
  { name: 'JPG to PDF', icon: require('../../assets/tools/9.png'), path: '/img-pdf', dbName: 'Image to PDF' },
  { name: 'PDF to JPG', icon: require('../../assets/tools/7.png'), path: '/pdf-jpg', dbName: 'PDF to JPG' },
  { name: 'Page Number', icon: require('../../assets/tools/6.png'), path: '/page-number', dbName: 'Page Number PDF' },
  { name: 'Watermark PDF', icon: require('../../assets/tools/11.png'), path: '/watermark-pdf', dbName: 'Watermark PDF' },
  { name: 'Forms', icon: require('../../assets/tools/form.png'), path: '/forms', dbName: 'Forms', isFree: true },
  { name: 'Speech To Text', icon: require('../../assets/tools/26.png'), path: '/speech-to-text-live', dbName: 'Speech To Text', isFree: true },
  { name: 'Merge PDF', icon: require('../../assets/tools/1.png'), path: '/merge-pdf', dbName: 'Merge PDF' },
  { name: 'Split PDF', icon: require('../../assets/tools/2.png'), path: '/split-pdf', dbName: 'Split PDF' },
  { name: 'Convert Image', icon: require('../../assets/tools/25.png'), path: '/convert-image', dbName: 'Convert Image' },
  { name: 'Crop Image', icon: require('../../assets/tools/19.png'), path: '/crop-image', dbName: 'Crop Image' },
  { name: 'Compress PDF', icon: require('../../assets/tools/3.png'), path: '/compress-pdf', dbName: 'Compress PDF' },
  // { name: 'PDF OCR Free', icon: require('../../assets/tools/5.png'), path: '/pdf-ocr-free', dbName: 'PDF OCR Free' },
  { name: 'Compress Image', icon: require('../../assets/tools/20.png'), path: '/compress-image', dbName: 'Compress Image' },
  { name: 'Watermark Image', icon: require('../../assets/tools/23.png'), path: '/watermark-image', dbName: 'Watermark Image' },
  { name: 'Resize Image', icon: require('../../assets/tools/18.png'), path: '/resize-image', dbName: 'Resize Image' },
  { name: 'Upscale Image', icon: require('../../assets/tools/21.png'), path: '/upscale-image', dbName: 'Upscale Image' },
  { name: 'Remove Background', icon: require('../../assets/tools/22.png'), path: '/remove-background', dbName: 'Remove Background' },
  // { name: 'Official Websites', icon: require('../../assets/tools/1.png'), path: '/Official-Websites' },
  // { name: 'Repair PDF', icon: require('../../assets/tools/13.png'), path: '/repair-pdf' },
  // { name: 'Rotate PDF', icon: rotatePdfIcon, path: '/rotate-pdf' },
  // { name: 'Extract PDF Text', icon: require('../../assets/tools/17.png'), path: '/extract-pdf' },
  // { name: 'Rotate Image', icon: rotateImageIcon, path: '/rotate-image' },
  // { name: 'Speech To Text', icon: watermarkImageIcon, path: '/speech-to-text' },

  
];

const ToolsPage = () => {
  const [showChatGPT, setShowChatGPT] = useState(false);
  const [toolPrices, setToolPrices] = useState({});
  const navigate = useNavigate(); // Hook to handle navigation
  const [pdfCredits, setPdfCredits] = useState(0);
  const [loadingCredits, setLoadingCredits] = useState(true); // Loading state for credits
  const [loadingTools, setLoadingTools] = useState(true);

  useEffect(() => {
    const fetchUserPdfCredits = async (userId) => {
      try {
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const currentBalance = userData.pdfCredits || 0; // Default to 0 if pdfCredits is undefined
          setPdfCredits(currentBalance); // Update state with fetched balance
        } else {
          console.warn('No user document found for the given UID');
        }
      } catch (error) {
        console.error('Error fetching user pdfCredits:', error);
      } finally {
        setLoadingCredits(false); // Stop showing the loader
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const userId = user.uid;
        fetchUserPdfCredits(userId);
      } else {
        navigate('/'); // Redirect to login if user is not authenticated
      }
    });

    return () => unsubscribe(); // Clean up the listener on component unmount
  }, [navigate]);
  
  

  useEffect(() => {
    const fetchToolPrices = async () => {
      const prices = {};
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const querySnapshot = await getDocs(toolsCollection);

        querySnapshot.forEach((doc) => {
          const toolData = doc.data();
          prices[toolData.tool] = toolData.price;
        });

        setToolPrices(prices);
      } catch (error) {
        console.error('Error fetching tool prices:', error);
      } finally {
        setLoadingTools(false); // Stop showing the loader
      }
    };

    fetchToolPrices();
  }, []);

  // Function to handle tool clicks
  const handleToolClick = (path, isSpecial) => {
    if (isSpecial) {
      setShowChatGPT(true); // Show ChatGPT for special tools
    } else {
      navigate(path); // Navigate to respective tool page for normal tools
    }
  };

  // If ChatGPT should be shown, render it
  if (showChatGPT) {
    return <ChatGPT />;
  }

  // Logout and redirect to login
  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out the user from Firebase
      localStorage.removeItem('isLoggedIn');
      navigate('/'); // Redirect to the login page
      window.location.reload(); // Refresh the page after logout
    } catch (error) {
      console.error('Error during logout:', error);
      alert('Failed to logout. Please try again.');
    }
  };

  // Render the tool selection page
  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', }}>
    <div>
    <button onClick={handleLogout} className="tools-button">Logout</button>
  </div>
    </div>
    <div className="tools-page">
  <h1>Every tool you need to work with PDFs and Images</h1>
  <p style={{fontSize: '18px'}}>Select a tool to get started:</p>
  <div
    style={{
      fontSize: '18px',
      fontWeight: '500',
      color: '#c09254',
      textAlign: 'center',
      marginTop: '0px',
      marginBottom: '30px',
      padding: '5px 15px',
      border: '1px solid #c09254',
      borderRadius: '10px',
      display: 'inline-block',
    }}
  >
    {loadingCredits ? <Skeleton width={150} height={20} /> : `Credits Available: ${pdfCredits}`}
  </div>

  {/* PDF Tools Section */}
  <h2 style={{textAlign: 'left', marginBottom: '15px', marginTop: '20px'}}>PDF Tools:</h2>
  <div className="tools-container">
    <div className="tools-grid">
      {tools
        .filter((tool) =>
          [
            'PDF OCR',
            'Translate PDF',
            'Speech To Translation',
            'PDF to Word',
            'Office to PDF',
            'Image to PDF',
            'PDF to JPG',
            'Protect PDF',
            'PDF to PDF/A',
            'Unlock PDF',
            'Page Number PDF',
            'Watermark PDF',
            'Validate PDF/A',
            'Merge PDF',
            'Split PDF',
            'Compress PDF',
            'Word to PDF'
          ].includes(tool.dbName)
        )
        .map((tool, index) => (
          <div key={index} className="tool-card" onClick={() => handleToolClick(tool.path, false)}>
            {tool.isFree ? (
              <p
                style={{
                  fontSize: '18px',
                  color: '#32CD32',
                  textAlign: 'center',
                  border: '1px solid #32CD32',
                  padding: '3px',
                  borderRadius: 20,
                }}
              >
                Free
              </p>
            ) : (
              <p
                style={{
                  fontSize: '18px',
                  color: '#c09254',
                  textAlign: 'center',
                  border: '1px solid #c09254',
                  padding: '3px',
                  borderRadius: 20,
                }}
              >
                {loadingTools ? <Skeleton width={50} height={20} /> : `${toolPrices[tool.dbName]} Credits`}
              </p>
            )}
            <img src={tool.icon} alt={`${tool.name} Icon`} className="tool-icon" />
            <h2>{tool.name}</h2>
          </div>
        ))}
    </div>
  </div>

  {/* Image Tools Section */}
  <h2 style={{textAlign: 'left', marginBottom: '15px', marginTop: '40px'}}>Image Tools</h2>
  <div className="tools-container">
    <div className="tools-grid">
      {tools
        .filter((tool) =>
          ['Compress Image', 'Convert Image', 'Remove Background', 'Watermark Image', 'Upscale Image', 'Crop Image', 'Resize Image'].includes(tool.dbName)
        )
        .map((tool, index) => (
          <div key={index} className="tool-card" onClick={() => handleToolClick(tool.path, false)}>
            {tool.isFree ? (
              <p
                style={{
                  fontSize: '18px',
                  color: '#32CD32',
                  textAlign: 'center',
                  border: '1px solid #32CD32',
                  padding: '3px',
                  borderRadius: 20,
                }}
              >
                Free
              </p>
            ) : (
              <p
                style={{
                  fontSize: '18px',
                  color: '#c09254',
                  textAlign: 'center',
                  border: '1px solid #c09254',
                  padding: '3px',
                  borderRadius: 20,
                }}
              >
                {loadingTools ? <Skeleton width={50} height={20} /> : `${toolPrices[tool.dbName]} Credits`}
              </p>
            )}
            <img src={tool.icon} alt={`${tool.name} Icon`} className="tool-icon" />
            <h2>{tool.name}</h2>
          </div>
        ))}
    </div>
  </div>

  {/* Free Tools Section */}
  <h2 style={{textAlign: 'left', marginBottom: '15px', marginTop: '40px'}}>Free Tools</h2>
  <div className="tools-container">
    <div className="tools-grid">
      {tools
        .filter((tool) => ['Forms', 'Speech To Text'].includes(tool.dbName))
        .map((tool, index) => (
          <div key={index} className="tool-card" onClick={() => handleToolClick(tool.path, false)}>
            <p
              style={{
                fontSize: '18px',
                color: '#32CD32',
                textAlign: 'center',
                border: '1px solid #32CD32',
                padding: '3px',
                borderRadius: 20,
              }}
            >
              Free
            </p>
            <img src={tool.icon} alt={`${tool.name} Icon`} className="tool-icon" />
            <h2>{tool.name}</h2>
          </div>
        ))}
    </div>
  </div>
</div>

    </>
  );
};

export default ToolsPage;
