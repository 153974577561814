import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { storage, db, auth } from '../../firebaseConfig'; // Firebase setup
import './WatermarkImage.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png'

const WatermarkImage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [watermarkMode, setWatermarkMode] = useState('text');
  const [watermarkText, setWatermarkText] = useState('');
  const [watermarkImage, setWatermarkImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [toolPrice, setToolPrice] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [watermarkedFileUrl, setWatermarkedFileUrl] = useState(null);
  const fileInputRef = useRef(null);
  const watermarkImageInputRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation

  const PUBLIC_KEY = 'project_public_89bba9932e7a274620c5d22eb4ce981a_9dOWN7a1ea85b9a31ff5f2d6d0d3924dc594b';
  const AUTH_API_URL = 'https://api.ilovepdf.com/v1/auth';

  useEffect(() => {
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Watermark Image'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price);
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  const handleFileSelection = (event) => {
    setSelectedFile(event.target.files[0]);
    setErrorMessage(null);
  };

  const handleWatermarkModeChange = (event) => {
    setWatermarkMode(event.target.value);
    setErrorMessage(null);
  };

  const handleWatermarkTextChange = (event) => {
    setWatermarkText(event.target.value);
  };

  const handleWatermarkImageSelection = (event) => {
    setWatermarkImage(event.target.files[0]);
  };

  const confirmProcess = async () => {
    try {
      if (!watermarkText.trim()) {
        setErrorMessage('watermark text cannot be empty.'); // Set error message if password is empty
        return; // Stop the process if password is empty
      }

      setIsModalOpen(false); // Close the modal initially
  
      // Fetch user balance
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        // const currentBalance = userData.balance || 0;
          const currentBalance = userData.pdfCredits || 0;
  
        if (currentBalance < toolPrice) {
          setErrorMessage('Insufficient credits.');
          return; // Stop the process if the user doesn't have enough balance
        }
  
        setIsModalOpen(true); // Open confirmation modal if sufficient balance
      } else {
        setErrorMessage('User not found.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while checking wallet balance.');
    }
  };

  const handleApplyWatermark = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select an image file to watermark.');
      return;
    }
  
    if (watermarkMode === 'text' && !watermarkText.trim()) {
      setErrorMessage('Please enter the watermark text.');
      return;
    }
  
    if (watermarkMode === 'image' && !watermarkImage) {
      setErrorMessage('Please select an image for the watermark.');
      return;
    }
  
    setIsProcessing(true);
    setErrorMessage(null);
    setIsModalOpen(false); // Close modal
  
    try {
      const token = await getToken();
      const { server, task } = await startTask(token, 'watermarkimage');
  
      const serverFilename = await uploadFile(server, task, selectedFile, token);
      let watermarkImageFilename = null;
  
      if (watermarkMode === 'image') {
        watermarkImageFilename = await uploadFile(server, task, watermarkImage, token);
      }
  
      const processedFileResponse = await processFile(
        server,
        task,
        serverFilename,
        selectedFile.name,
        watermarkMode,
        watermarkText.trim(),
        watermarkImageFilename,
        token
      );
  
      // Download the watermarked image from the API
      const watermarkedImageBlob = await downloadFile(server, task, token);
  
      // Upload the watermarked image to Firebase
      const downloadUrl = await uploadImageToFirebase(watermarkedImageBlob);
      setWatermarkedFileUrl(downloadUrl);
  
      setConfirmationMessage(`${toolPrice} Credits have been deducted from your wallet.`);
      await deductAmountFromWallet(auth.currentUser.uid, toolPrice);
    } catch (error) {
      setErrorMessage('An error occurred while applying the watermark. Please try again later.');
    } finally {
      setIsProcessing(false);
    }
  };
  

  const resetPage = () => {
    setSelectedFile(null);
    setWatermarkMode('text');
    setWatermarkText('');
    setWatermarkImage(null);
    setIsProcessing(false);
    setErrorMessage(null);
    setConfirmationMessage('');
    setIsCopied(false);
    setWatermarkedFileUrl(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    if (watermarkImageInputRef.current) {
      watermarkImageInputRef.current.value = '';
    }
  };

  const getToken = async () => {
    const response = await fetch(AUTH_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_key: PUBLIC_KEY })
    });
    const data = await response.json();
    return data.token;
  };

  const startTask = async (token, tool) => {
    try {
      const response = await fetch(`https://api.ilovepdf.com/v1/start/${tool}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      
      const data = await response.json();
  
      if (!response.ok || !data.task || !data.server) {
        throw new Error(`Failed to start task: ${data.message || 'Unknown error'}`);
      }
  
      return data;
    } catch (error) {
      console.error('Start Task Error:', error.message);
      throw new Error('Failed to start task');
    }
  };
  

  const uploadFile = async (server, task, file, token) => {
    const formData = new FormData();
    formData.append('task', task);
    formData.append('file', file);

    const response = await fetch(`https://${server}/v1/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData
    });

    const data = await response.json();
    return data.server_filename;
  };

  const processFile = async (
    server,
    task,
    serverFilename,
    originalFilename,
    mode,
    text,
    watermarkImageFilename,
    token
  ) => {
    const requestBody = {
      task: task,
      tool: 'watermarkimage',
      files: [
        {
          server_filename: serverFilename,
          filename: originalFilename,
        }
      ],
      elements: [
        {
          type: mode,
          text: mode === 'text' ? text : undefined,
          server_filename: mode === 'image' ? watermarkImageFilename : undefined,
          gravity: 'Center',
          width_percent: 50,
          height_percent: 50,
          transparency: 25,
          x_pos_percent: 50, // Set X position of the watermark to 50% (centered)
          y_pos_percent: 50, // Set Y position of the watermark to 50% (centered)
        }
      ]
    };
  
    try {
      const response = await fetch(`https://${server}/v1/process`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Process Error Response:', errorText);
        throw new Error(`Process failed with status ${response.status}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('Process File Error:', error.message);
      throw new Error('Failed to process file');
    }
  };
  

  const downloadFile = async (server, task, token) => {
    try {
      const response = await fetch(`https://${server}/v1/download/${task}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Download Error:', errorText);
        throw new Error(`Download failed with status ${response.status}`);
      }
  
      // Return the blob (image file) from the response
      return await response.blob();
    } catch (error) {
      console.error('Download File Error:', error.message);
      throw new Error('Failed to download file');
    }
  };
  
  
  

  const uploadImageToFirebase = async (imageBlob) => {
    try {
      const fileName = `watermarked_image_${Date.now()}.png`;
      const storageRef = ref(storage, `watermarkedImages/${fileName}`);
      const snapshot = await uploadBytes(storageRef, imageBlob);
      const downloadUrl = await getDownloadURL(snapshot.ref);
  
      await addDoc(collection(db, 'watermarkedImages'), {
        downloadUrl,
        timestamp: new Date(),
      });
  
      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload watermarked image to Firebase.');
    }
  };
  

  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      // const currentBalance = userData.balance || 0;
          const currentBalance = userData.pdfCredits || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        // await updateDoc(userDocRef, { balance: newBalance });
        await updateDoc(userDocRef, { pdfCredits: newBalance });
      } else {
        throw new Error('Insufficient credits.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  const copyLinkToClipboard = async () => {
    if (!watermarkedFileUrl) {
      setConfirmationMessage('No link to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(watermarkedFileUrl);
      setIsCopied(true);
      setConfirmationMessage('Watermarked image link copied to clipboard. Paste the copied link in any browser to download the image.');
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };

  return (
    <>
     <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className="watermark-image-container">
      <h1>Watermark Image</h1>
      <p>Add a watermark to your image file.</p>

      <div className="file-upload-container">
        <input 
          type="file" 
          accept='.jpg'
          onChange={handleFileSelection} 
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFile ? 'none' : 'block' }}
        >
          Select Image file
        </button>
      </div>

      {selectedFile && (
        <div className="image-settings">
          <div className="pdf-preview">
            <div className='pdf-thumbnail'>
            <span>FileName: {selectedFile.name}</span>
            <span>FileSize: {(selectedFile.size / 1024 / 1024).toFixed(2)} MB</span>
            </div>
          </div>
          <label>
            Watermark Mode:
            <select value={watermarkMode} onChange={handleWatermarkModeChange}>
              <option value="text">Text</option>
              <option value="image">Image</option>
            </select>
          </label>

          {watermarkMode === 'text' && (
            <label>
              Watermark Text:
              <input
                type="text"
                placeholder="Type watermark text"
                value={watermarkText}
                onChange={handleWatermarkTextChange}
              />
            </label>
          )}

          {watermarkMode === 'image' && (
            <div>
              <label>
                Select Watermark Image:
                <input
                  type="file"
                  accept='.jpg,.png'
                  onChange={handleWatermarkImageSelection}
                  ref={watermarkImageInputRef}
                  className="file-input"
                />
                <button
                  className="file-upload-button"
                  onClick={() => watermarkImageInputRef.current.click()}
                  style={{ display: watermarkImage ? 'none' : 'block' }}
                >
                  Select Image
                </button>
              </label>

              {watermarkImage && (
                <div className="image-preview">
                  <img src={URL.createObjectURL(watermarkImage)} alt="Watermark Preview" />
                  <span>{watermarkImage.name}</span>
                </div>
              )}
            </div>
          )}

          <button
            className="crop-button"
            onClick={confirmProcess}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Apply Watermark'}
          </button>
        </div>
      )}

      {watermarkedFileUrl && (
        <div className="share-options">
          <button className="share-button" onClick={copyLinkToClipboard}>
            {isCopied ? 'Copied!' : 'Copy Link'}
          </button>
        </div>
      )}

        

      {errorMessage && <div className="error-message" style={{marginTop: '25px'}}>{errorMessage}</div>}
      {confirmationMessage && (
        <div className="confirmation-message" style={{marginTop: '25px'}}>{confirmationMessage}</div>
      )}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>
              Are you sure you want to deduct {toolPrice} credits for the watermark process?
            </p>
            <div className="modal-buttons">
              <button className="modal-confirm" onClick={handleApplyWatermark}>
                Confirm
              </button>
              <button
                className="modal-cancel"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default WatermarkImage;
