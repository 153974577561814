import React, { useState, useRef, useEffect } from 'react';
import { Document, Packer, Paragraph } from 'docx';
import { saveAs } from 'file-saver'; // Required for saving docx files
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import * as pdfjsLib from 'pdfjs-dist'; // For counting PDF pages
import * as mammoth from 'mammoth'; // For reading DOCX files
import { storage, db, auth } from '../../firebaseConfig'; // Firebase setup
import './PdfTranslate.css';
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png'

const PdfTranslate = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [translatedText, setTranslatedText] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [targetLanguage, setTargetLanguage] = useState('en');
  const [errorMessage, setErrorMessage] = useState(null);
  const [toolPrice, setToolPrice] = useState(null); // Tool price from Firestore
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [translatedFileUrl, setTranslatedFileUrl] = useState(null); // URL of the uploaded docx file
  const fileInputRef = useRef(null);
  const [pageCount, setPageCount] = useState(null); // For keeping track of page count
  const navigate = useNavigate(); // Hook for navigation

  const API_KEY = 'APY0x6ALFmgXN4t7nn81S3SdqqsP5FVXmr3Bb5NoFs1H5ddn1QCnLwMUqmY3AKtOulE';
  const TRANSLATE_API_URL = 'https://api.apyhub.com/translate/file';

  // Fetch tool price from Firestore
  useEffect(() => {
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Translate PDF'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price); // Set tool price from Firestore
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  const handleFileSelection = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setSelectedFile(null);
      setErrorMessage(null);
      setPageCount(null); // Reset page count
      return;
    }

    const fileExtension = file.name.split('.').pop().toLowerCase();
    setSelectedFile(file);
    setErrorMessage(null);
    setPageCount(null); // Reset the page count

    if (fileExtension === 'pdf') {
      const pdfPageCount = await getPdfPageCount(file);
      if (pdfPageCount > 40) {
        setErrorMessage('Cannot process PDF files larger than 40 pages. Please upload a smaller file.');
        setPageCount(pdfPageCount);
      } else {
        setPageCount(pdfPageCount);
      }
    } else if (fileExtension === 'docx') {
      const docxPageCount = await getDocxPageCount(file);
      if (docxPageCount > 40) {
        setErrorMessage('Cannot process DOCX files larger than 40 pages. Please upload a smaller file.');
        setPageCount(docxPageCount);
      } else {
        setPageCount(docxPageCount);
      }
    } else {
      setErrorMessage('Only PDF or DOCX files are allowed.');
    }
  };

  const getPdfPageCount = async (file) => {
    const loadingTask = pdfjsLib.getDocument(URL.createObjectURL(file));
    const pdf = await loadingTask.promise;
    return pdf.numPages;
  };

  const getDocxPageCount = async (file) => {
    const { value } = await mammoth.extractRawText({ arrayBuffer: file });
    const wordsPerPage = 300; // Approximate number of words per page
    const wordCount = value.split(/\s+/).length;
    return Math.ceil(wordCount / wordsPerPage);
  };

  const handleLanguageChange = (event) => {
    setTargetLanguage(event.target.value);
  };

  const confirmProcess = async () => {
    if (pageCount > 40) {
      setErrorMessage('Cannot process files larger than 40 pages.');
      return;
    }
  
    try {
      // Fetch user balance
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        // const currentBalance = userData.balance || 0;
          const currentBalance = userData.pdfCredits || 0;
  
        if (currentBalance < toolPrice) {
          setErrorMessage('Insufficient credits.');
          return; // Stop the process if the user doesn't have enough balance
        }
  
        setIsModalOpen(true); // Open confirmation modal if balance is sufficient
      } else {
        setErrorMessage('User details not found. Please log in again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while checking wallet balance.');
    }
  };
  

  const handleTranslate = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a PDF or DOCX file for translation.');
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);
    setIsModalOpen(false);

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('language', targetLanguage);

      const response = await fetch(`${TRANSLATE_API_URL}?transliteration=false`, {
        method: 'POST',
        headers: {
          'apy-token': API_KEY,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Translation failed with status ${response.status}`);
      }

      const data = await response.json();
      setTranslatedText(data.translation);

      // Convert the translated text to DOCX
      const docxBlob = await createDocxBlob(data.translation);
      const downloadUrl = await uploadDocxToFirebase(docxBlob);
      setTranslatedFileUrl(downloadUrl); // Set download URL for copy link functionality

      setConfirmationMessage(`${toolPrice} Credits have been deducted from your wallet.`);
      await deductAmountFromWallet(auth.currentUser.uid, toolPrice);
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred during translation. Please try again later.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Create DOCX blob from translated text
  const createDocxBlob = async (text) => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [new Paragraph(text)],
        },
      ],
    });
    return await Packer.toBlob(doc); // Return DOCX blob
  };

  // Upload DOCX to Firebase Storage and Firestore
  const uploadDocxToFirebase = async (docxBlob) => {
    try {
      const fileName = `translated_docx_${Date.now()}.docx`;
      const storageRef = ref(storage, `translatedDocs/${fileName}`);
      const snapshot = await uploadBytes(storageRef, docxBlob); // Upload the DOCX blob to Firebase Storage
      const downloadUrl = await getDownloadURL(snapshot.ref);

      // Store download URL in Firestore
      await addDoc(collection(db, 'translatedDocs'), {
        downloadUrl,
        timestamp: new Date(),
      });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload translated DOCX to Firebase.');
    }
  };

  // Deduct coins from the user's wallet
  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      // const currentBalance = userData.balance || 0;
          const currentBalance = userData.pdfCredits || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        // await updateDoc(userDocRef, { balance: newBalance });
        await updateDoc(userDocRef, { pdfCredits: newBalance });
      } else {
        throw new Error('Insufficient credits.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  const copyLinkToClipboard = async () => {
    if (!translatedFileUrl) {
      setConfirmationMessage('No link to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(translatedFileUrl);
      setIsCopied(true);
      setConfirmationMessage('Translated DOCX link copied to clipboard. Paste the copied link in any browser to download the file.');
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };

  return (
    <>
     <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className="translate-pdf-container">
      <h1>PDF and DOCX Translator</h1>
      <p>Translate your PDF or DOCX files into another language. download as docx file</p>
      <p style={{ color: 'red', marginTop: '30px' }}>
        Note: Please upload a PDF or DOCX file less than 40 pages or use our 
        <a href="#!" className="split-link" onClick={() => navigate('/split-pdf')} style={{ color: 'blue', textDecoration: 'none', marginLeft: '5px', marginRight: '5px' }}>
          Split Pdf
        </a>
        tool to split large pdf files. 
      </p>
      
      
      <div className="file-upload-container">
        <input 
          type="file" 
          accept=".pdf,.docx" 
          onChange={handleFileSelection} 
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFile ? 'none' : 'block' }}
        >
          Select File
        </button>
      </div>

      {selectedFile && (
        <div className="pdf-preview-container">
          <div className="pdf-thumbnail">
            <span>FileName: {selectedFile.name}</span>
            <span>Page Count: {pageCount}</span>
          </div>

          <div className="translate-language-container">
            <label htmlFor="translate-language">Translate to:</label>
            <select 
              id="translate-language" 
              value={targetLanguage} 
              onChange={handleLanguageChange}
            >
              <option value="en">English</option>
              <option value="ta">Tamil</option>
              <option value="te">Telugu</option>
              <option value="ml">Malayalam</option>
              <option value="kn">Kannada</option>
              <option value="hi">Hindi</option>
              <option value="gu">Gujarati</option>
              <option value="mr">Marathi</option>
            </select>
          </div>

          <button 
            className="translate-button" 
            onClick={confirmProcess}
            disabled={isProcessing || pageCount > 40} // Disable if more than 40 pages
          >
            {isProcessing ? 'Processing...' : 'Start Translation'}
          </button>
        </div>
      )}

      {translatedFileUrl && (
        <div className="share-options">
          <button className="share-button" onClick={copyLinkToClipboard}>
            {isCopied ? 'Copied!' : 'Copy Link'}
          </button>
        </div>
      )}

      {errorMessage && <div className="error-message" style={{marginTop: '25px'}}>{errorMessage}</div>}
      {confirmationMessage && <div className="confirmation-message" style={{marginTop: '25px'}}>{confirmationMessage}</div>}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to deduct {toolPrice} credits for the translation?</p>
            <div className="modal-buttons">
              <button className="modal-confirm" onClick={handleTranslate}>Ok</button>
              <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default PdfTranslate;
