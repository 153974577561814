import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { storage, db, auth } from '../../firebaseConfig'; // Firebase setup
import './PageNumberPdf.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png'

import eg1 from '../../assets/pageNumber/page num.jpg'
import eg7 from '../../assets/pageNumber/7.jpg'
import eg8 from '../../assets/pageNumber/8.jpg'

const images = [
  { title: 'First Cover Page', url: eg7 },
  { title: 'Front Facing Page', url: eg8 },
  { title: 'Page Number', url: eg1 },
];


const ExampleModal = ({ isOpen, onClose, image }) => {
  if (!isOpen || !image) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-slider">
        <h3>{image.title}</h3>
        <img src={image.url} alt={image.title} style={{ width: '100%', height: 'auto' }} />
        <button onClick={onClose} style={{ marginTop: 20, backgroundColor: 'green', color: '#fff', padding: '8px 12px', border: 'none', borderRadius: '5px', fontSize: '16px' }}>Close</button>
      </div>
    </div>
  );
};

const PageNumberPdf = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [facingPages, setFacingPages] = useState(false);
  const [firstCover, setFirstCover] = useState(false);
  const [pages, setPages] = useState('all');
  const [startingNumber, setStartingNumber] = useState(1);
  const [verticalPosition, setVerticalPosition] = useState('bottom');
  const [horizontalPosition, setHorizontalPosition] = useState('center');
  const [verticalAdjustment, setVerticalAdjustment] = useState(0);
  const [horizontalAdjustment, setHorizontalAdjustment] = useState(0);
  const [fontFamily, setFontFamily] = useState('Arial Unicode MS');
  const [fontSize, setFontSize] = useState(14);
  const [fontColor, setFontColor] = useState('#000000');
  const [isProcessing, setIsProcessing] = useState(false);
  const [toolPrice, setToolPrice] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfFileUrl, setPdfFileUrl] = useState(null); // Store the Firebase URL of the PDF
  const [isCopied, setIsCopied] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false); // Separate state for image modal


  const PUBLIC_KEY = 'project_public_89bba9932e7a274620c5d22eb4ce981a_9dOWN7a1ea85b9a31ff5f2d6d0d3924dc594b';
  const AUTH_API_URL = 'https://api.ilovepdf.com/v1/auth';

  useEffect(() => {
    // Fetch tool price from Firestore
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Page Number PDF'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price); // Set tool price from Firestore
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  const handleFileSelection = (event) => {
    setSelectedFile(event.target.files[0]);
    setErrorMessage(null);
    setIsCopied(false);
    setConfirmationMessage('');
  };

  const confirmProcess = async () => {
    try {
      setIsModalOpen(false); // Close the modal initially
  
      // Fetch user balance
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        // const currentBalance = userData.balance || 0;
          const currentBalance = userData.pdfCredits || 0;
  
        if (currentBalance < toolPrice) {
          setErrorMessage('Insufficient credits.');
          return; // Stop the process if the user doesn't have enough balance
        }
  
        setIsModalOpen(true); // Open confirmation modal if sufficient balance
      } else {
        setErrorMessage('User not found.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while checking wallet balance.');
    }
  };

  const handleApplyPageNumbers = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a PDF file to apply page numbers.');
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);
    setIsModalOpen(false);

    try {
      const token = await getToken();
      const { server, task } = await startTask(token, 'pagenumber');

      const serverFilename = await uploadFile(server, task, selectedFile, token);

      const pdfBlob = await processFile(server, task, serverFilename, selectedFile.name, token);
      const downloadUrl = await uploadPdfToFirebase(pdfBlob);

      setPdfFileUrl(downloadUrl); // Set the download URL for display
      setConfirmationMessage(`${toolPrice} Credits have been deducted from your wallet.`);
      await deductAmountFromWallet(auth.currentUser.uid, toolPrice);
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred while applying page numbers. Please try again later.');
    } finally {
      setIsProcessing(false);
    }
  };

  const resetPage = () => {
    setSelectedFile(null);
    setFacingPages(false);
    setFirstCover(false);
    setPages('all');
    setStartingNumber(1);
    setVerticalPosition('bottom');
    setHorizontalPosition('center');
    setVerticalAdjustment(0);
    setHorizontalAdjustment(0);
    setFontFamily('Arial Unicode MS');
    setFontSize(14);
    setFontColor('#000000');
    setIsProcessing(false);
    setErrorMessage(null);
    setIsCopied(false);
    setPdfFileUrl(null);
    setConfirmationMessage('');

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const getToken = async () => {
    const response = await fetch(AUTH_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_key: PUBLIC_KEY })
    });

    const data = await response.json();
    return data.token;
  };

  const startTask = async (token, tool) => {
    const response = await fetch(`https://api.ilovepdf.com/v1/start/${tool}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const text = await response.text();
    if (!response.ok || !text) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = JSON.parse(text);
    if (!data.server || !data.task) {
      throw new Error('Failed to retrieve server or task ID from response');
    }

    return data;
  };

  const uploadFile = async (server, task, file, token) => {
    const formData = new FormData();
    formData.append('task', task);
    formData.append('file', file);

    const response = await fetch(`https://${server}/v1/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`Upload failed with status ${response.status}`);
    }

    const data = await response.json();
    return data.server_filename;
  };

  const processFile = async (server, task, serverFilename, originalFilename, token) => {
    const requestBody = {
      task: task,
      tool: 'pagenumber',
      files: [
        {
          server_filename: serverFilename,
          filename: originalFilename,
        }
      ],
      facing_pages: facingPages,
      first_cover: firstCover,
      pages: pages,
      starting_number: startingNumber,
      vertical_position: verticalPosition,
      horizontal_position: horizontalPosition,
      vertical_position_adjustment: verticalAdjustment,
      horizontal_position_adjustment: horizontalAdjustment,
      font_family: fontFamily,
      font_size: fontSize,
      font_color: fontColor,
      text: '{n}',
    };

    const response = await fetch(`https://${server}/v1/process`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Process failed with status ${response.status}: ${errorText}`);
    }

    return downloadFile(server, task, token);
  };

  const downloadFile = async (server, task, token) => {
    const response = await fetch(`https://${server}/v1/download/${task}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Download failed with status ${response.status}`);
    }

    const blob = await response.blob();
    return blob;
  };

  const uploadPdfToFirebase = async (pdfBlob) => {
    try {
      const fileName = `page_numbered_pdf_${Date.now()}.pdf`;
      const storageRef = ref(storage, `pageNumberPdfs/${fileName}`);
      const snapshot = await uploadBytes(storageRef, pdfBlob); // Upload the PDF blob to Firebase Storage
      const downloadUrl = await getDownloadURL(snapshot.ref); // Get the file's download URL

      // Store the download URL in Firestore
      await addDoc(collection(db, 'pageNumberPdfs'), {
        downloadUrl,
        timestamp: new Date(),
      });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload Page Number PDF to Firebase.');
    }
  };

  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      // const currentBalance = userData.balance || 0;
          const currentBalance = userData.pdfCredits || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        // await updateDoc(userDocRef, { balance: newBalance });
        await updateDoc(userDocRef, { pdfCredits: newBalance });
      } else {
        throw new Error('Insufficient credits.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  const copyLinkToClipboard = async () => {
    if (!pdfFileUrl) {
      setConfirmationMessage('No link to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(pdfFileUrl);
      setIsCopied(true); // Set to true when the link is copied
      setConfirmationMessage('Converted PDF link copied to clipboard. paste copied link in any browser to download pdf.');
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };

  // Open the image modal when clicking the image
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsImageModalOpen(true); // Open image modal
  };

  // Close the image modal
  const closeImageModal = () => {
    setSelectedImage(null);
    setIsImageModalOpen(false);
  };
    

  
  

  return (
    <>
     <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className="page-number-pdf-container">
      <h1>Add Page Numbers to PDF</h1>
      <p>Configure the settings below to apply page numbers to your PDF.</p>
      <div className="grid-container">
          {images.map((image, index) => (
            <div key={index} className="grid-item" onClick={() => handleImageClick(image)}>
              <img src={image.url} alt={image.title} className="grid-image" />
              <p>{image.title}</p>
            </div>
          ))}
        </div>

      <div className="file-upload-container">
        <input
          type="file"
          accept='.pdf'
          onChange={handleFileSelection}
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFile ? 'none' : 'block' }}
        >
          Select PDF file
        </button>
      </div>

      {selectedFile && (
        <div className="pdf-preview-container">
          <div className="pdf-thumbnail">
            <span>FileName: {selectedFile.name}</span>
            <span>FileSize: {(selectedFile.size / 1024 / 1024).toFixed(2)} MB</span>
          </div>
        </div>
      )}

      {selectedFile && (
        <div className="pdf-settings">
          <label>
            Front Facing Pages:
            <input
              type="checkbox"
              checked={facingPages}
              onChange={(e) => setFacingPages(e.target.checked)}
            />
          </label>

          <label>
            First Cover Page:
            <input
              type="checkbox"
              checked={firstCover}
              onChange={(e) => setFirstCover(e.target.checked)}
            />
          </label>

          <label>
            Pages to Number:
            <input
              type="text"
              value={pages}
              onChange={(e) => setPages(e.target.value)}
              placeholder="e.g., all, 3-end, 1,3,4-9"
            />
          </label>

          <label>
            Starting Number:
            <input
              type="number"
              value={startingNumber}
              onChange={(e) => setStartingNumber(Number(e.target.value))}
              min="1"
            />
          </label>

          <label>
            Vertical Position:
            <select
              value={verticalPosition}
              onChange={(e) => setVerticalPosition(e.target.value)}
            >
              <option value="bottom">Bottom</option>
              <option value="top">Top</option>
            </select>
          </label>

          <label>
            Horizontal Position:
            <select
              value={horizontalPosition}
              onChange={(e) => setHorizontalPosition(e.target.value)}
            >
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="right">Right</option>
            </select>
          </label>

          {/* <label>
            Vertical Adjustment:
            <input
              type="number"
              value={verticalAdjustment}
              onChange={(e) => setVerticalAdjustment(Number(e.target.value))}
            />
          </label>

          <label>
            Horizontal Adjustment:
            <input
              type="number"
              value={horizontalAdjustment}
              onChange={(e) => setHorizontalAdjustment(Number(e.target.value))}
            />
          </label> */}

          <label>
            Font Family:
            <select
              value={fontFamily}
              onChange={(e) => setFontFamily(e.target.value)}
            >
              <option value="Arial">Arial</option>
              <option value="Arial Unicode MS">Arial Unicode MS</option>
              <option value="Verdana">Verdana</option>
              <option value="Courier">Courier</option>
              <option value="Times New Roman">Times New Roman</option>
              <option value="Comic Sans MS">Comic Sans MS</option>
              <option value="WenQuanYi Zen Hei">WenQuanYi Zen Hei</option>
              <option value="Lohit Marathi">Lohit Marathi</option>
            </select>
          </label>

          <label>
            Font Size:
            <input
              type="number"
              value={fontSize}
              onChange={(e) => setFontSize(Number(e.target.value))}
              min="1"
            />
          </label>

          <label className="color-picker" style={{flexDirection: 'row', display: 'flex', margin: '20px 0'}}>
            Font Color:
            <input
              type="color"
              value={fontColor}
              onChange={(e) => setFontColor(e.target.value)}
            />
            <div
              className="color-preview"
              style={{ backgroundColor: fontColor }}
            ></div>
          </label>

          <button
            className="crop-button"
            onClick={confirmProcess}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Apply Page Numbers'}
          </button>
        </div>
      )}

<ExampleModal isOpen={isImageModalOpen} onClose={closeImageModal} image={selectedImage} />

      {pdfFileUrl && (
        <div className="share-options">
          <button className="share-button" onClick={copyLinkToClipboard}>
            {isCopied ? 'Copied!' : 'Copy Link'}
          </button>
        </div>
      )}

      {errorMessage && <div className="error-message" style={{marginTop: '25px'}}>{errorMessage}</div>}
      {confirmationMessage && <div className="confirmation-message" style={{marginTop: '25px'}}>{confirmationMessage}</div>}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to deduct {toolPrice} credits for applying page numbers?</p>
            <div className="modal-buttons">
              <button className="modal-confirm" onClick={handleApplyPageNumbers}>Ok</button>
              <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

    </div>
   
  </>
  );
};

export default PageNumberPdf;
