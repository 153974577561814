// import React, { useState, useEffect } from 'react';
// import { auth, db } from '../firebaseConfig';
// import { onAuthStateChanged, RecaptchaVerifier, signInWithPhoneNumber, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signOut } from 'firebase/auth';
// import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
// import { v4 as uuidv4 } from 'uuid';  // To generate unique session tokens
// import './LoginPage.css';
// import ToolsPage from '../Tools/ToolsPage/ToolsPage';
// import loginIcon1 from '../assets/login1.jpg';
// import loginIcon2 from '../assets/login2.jpg';
// import loginIcon3 from '../assets/login3.jpg';
// import ChatGPT from '../component/chatgpt';

// const LoginPage = () => {
//     const [currentImageIndex, setCurrentImageIndex] = useState(0);
//     const images = [loginIcon1, loginIcon2, loginIcon3];

//     const [email, setEmail] = useState('');
//     const [phoneNumber, setPhoneNumber] = useState('');
//     const [otp, setOtp] = useState(new Array(6).fill(''));
//     const [confirmResult, setConfirmResult] = useState(null);
//     const [isLoggedIn, setIsLoggedIn] = useState(false);
//     const [error, setError] = useState('');
//     const [otpMessage, setOtpMessage] = useState('');
//     const [loginMode, setLoginMode] = useState('phone');
//     const [isOtpSent, setIsOtpSent] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const [sessionToken, setSessionToken] = useState(null);
//     const [isOtpLoading, setIsOtpLoading] = useState(false);
//     const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(false);


//     useEffect(() => {
//         const interval = setInterval(() => {
//             setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
//         }, 3000);

//         return () => clearInterval(interval);
//     }, [images.length]);

//       // Support button click handler to open WhatsApp
//   const handleSupportClick = () => {
//     const phoneNumber = '7200399562';
//     const url = `https://wa.me/+91${phoneNumber}`;
//     window.open(url, '_blank');
//   };

//     useEffect(() => {
//         const unsubscribe = onAuthStateChanged(auth, (user) => {
//             if (user) {
//                 const uid = user.uid;
//                 checkSubscription(uid);
//             }
//         });

//         if (isSignInWithEmailLink(auth, window.location.href)) {
//             let emailForSignIn = window.localStorage.getItem('emailForSignIn') || '';
//             if (!emailForSignIn) {
//                 emailForSignIn = window.prompt('Please provide your email for confirmation');
//             }
//             if (emailForSignIn) {
//                 signInWithEmailLink(auth, emailForSignIn, window.location.href)
//                     .then((result) => {
//                         if (result.user) {
//                             const uid = result.user.uid;
//                             checkSubscription(uid);
//                         }
//                     })
//                     .catch((error) => {
//                         setError("Error signing in with email link. Please try again.");
//                         console.error("Error signing in with email link:", error);
//                     });
//             }
//         }

//         const subscriptionExpired = localStorage.getItem('subscriptionExpired');
//         if (subscriptionExpired) {
//             setError("Your subscription has expired. Please renew to continue.");
//             localStorage.removeItem('subscriptionExpired');
//         }

//         return () => unsubscribe();
//     }, []);

//     const handleLogin = async (e) => {
//         e.preventDefault();
//         setIsLoading(true);
//         try {
//             if (loginMode === 'email') {
//                 if (!isOtpSent) {
//                     setError("Please click 'Verify Email' to receive a login link.");
//                     setIsLoading(false);
//                     return;
//                 }

//                 if (isSignInWithEmailLink(auth, window.location.href)) {
//                     const emailForSignIn = window.localStorage.getItem('emailForSignIn') || email;
//                     const result = await signInWithEmailLink(auth, emailForSignIn, window.location.href);
//                     if (result.user) {
//                         const uid = result.user.uid;
//                         await handleSingleSessionLogin(uid);  // Check and handle single session
//                         await checkSubscription(uid);
//                     }
//                 } else {
//                     setError("Invalid email link. Please try again.");
//                     setIsLoading(false);
//                 }
//             } else if (loginMode === 'phone') {
//                 if (!phoneNumber || otp.some((digit) => digit === '')) {
//                     setError("Phone number and OTP are required.");
//                     setIsLoading(false);
//                     return;
//                 }
//                 const otpString = otp.join('');
//                 const result = await confirmResult.confirm(otpString);
//                 if (result.user) {
//                     const uid = result.user.uid;
//                     await handleSingleSessionLogin(uid);  // Check and handle single session
//                     await checkSubscription(uid);
//                 }
//             }
//         } catch (error) {
//             setError("Invalid credentials or user does not exist. Please try again.");
//             console.error("Error signing in:", error);
//             setIsLoading(false);
//         }
//     };

//     // Function to handle single session login
//     const handleSingleSessionLogin = async (uid) => {
//         const newSessionToken = uuidv4(); // Generate a unique session token
//         const userRef = doc(db, "users", uid);
      
//         try {
//           const userDoc = await getDoc(userRef);
//           if (userDoc.exists()) {
//             const userData = userDoc.data();
//             const storedSessionToken = userData.sessionToken;
      
//             // If the session token exists and it's not the current session, force logout from other devices
//             if (storedSessionToken && storedSessionToken !== newSessionToken) {
//               await updateDoc(userRef, { sessionToken: newSessionToken });
//               localStorage.setItem('sessionToken', newSessionToken);
//               console.log('Logged out from other devices, new session started');
//             } else {
//               await updateDoc(userRef, { sessionToken: newSessionToken });
//               localStorage.setItem('sessionToken', newSessionToken);
//             }
//           } else {
//             await setDoc(userRef, { sessionToken: newSessionToken });
//             localStorage.setItem('sessionToken', newSessionToken);
//             console.log('Session token created');
//           }
//         } catch (error) {
//           console.error("Error handling single session:", error);
//           throw error;
//         }
//       };
      
//     // const checkSubscription = async (uid) => {
//     //     try {
//     //         const userDoc = await getDoc(doc(db, "users", uid));
//     //         if (userDoc.exists()) {
//     //             const userData = userDoc.data();
//     //             if (userData.isSubscribed) {
//     //                 const subscriptionEndDate = new Date(userData.subscriptionEndDate.seconds * 1000);
//     //                 const currentDate = new Date();

//     //                 if (subscriptionEndDate > currentDate) {
//     //                     setIsLoading(false);
//     //                     setTimeout(() => {
//     //                         setIsLoggedIn(true);
//     //                         localStorage.setItem('isLoggedIn', 'true');
//     //                     }, 1000);
//     //                 } else {
//     //                     setError("Your subscription has expired. Please renew to continue.");
//     //                     setIsLoading(false);
//     //                 }
//     //             } else {
//     //                 setError("You are not subscribed to use this service.");
//     //                 setIsLoading(false);
//     //             }
//     //         } else {
//     //             setError("User data not found.");
//     //             setIsLoading(false);
//     //         }
//     //     } catch (error) {
//     //         console.error("Error checking subscription:", error);
//     //         setError("Error checking subscription status. Please try again.");
//     //         setIsLoading(false);
//     //     }
//     // };

//     // Update checkSubscription to set the subscription check state
//     const checkSubscription = async (uid) => {
//         try {
//             const userDoc = await getDoc(doc(db, "users", uid));
//             if (userDoc.exists()) {
//                 const userData = userDoc.data();
//                 if (userData.isSubscribed) {
//                     const subscriptionEndDate = new Date(userData.subscriptionEndDate.seconds * 1000);
//                     const currentDate = new Date();

//                     if (subscriptionEndDate > currentDate) {
//                         setTimeout(() => {
//                             setIsLoggedIn(true);
//                             localStorage.setItem('isLoggedIn', 'true');
//                         }, 1000);
//                     } else {
//                         setError("Your subscription has expired. Please renew to continue.");
//                     }
//                 } else {
//                     setError("You are not subscribed to use this service.");
//                 }
//             } else {
//                 setError("User data not found.");
//             }
//         } catch (error) {
//             console.error("Error checking subscription:", error);
//             setError("Error checking subscription status. Please try again.");
//         } finally {
//             setIsSubscriptionChecked(true); // Mark subscription check as complete
//             setIsLoading(false);
//         }
//     };

//     const handleSendOtpEmail = async () => {
//         try {
//             const actionCodeSettings = {
//                 url: window.location.href,
//                 handleCodeInApp: true,
//             };
//             await sendSignInLinkToEmail(auth, email, actionCodeSettings);
//             window.localStorage.setItem('emailForSignIn', email);
//             setIsOtpSent(true);
//             setError('Email sent! Please check your inbox.');
//         } catch (error) {
//             setError('Failed to send email OTP. Please try again.');
//             console.error('Error sending email OTP:', error);
//         }
//     };

//     const setUpRecaptcha = () => {
//         if (!window.recaptchaVerifier) {
//             window.recaptchaVerifier = new RecaptchaVerifier(
//                 auth,
//                 'recaptcha-container',
//                 {
//                     size: 'invisible',
//                     callback: (response) => {
//                         console.log('reCAPTCHA solved.');
//                     },
//                     'expired-callback': () => {
//                         console.log('reCAPTCHA expired; please try again.');
//                     },
//                 }
//             );
//         }
//     };

//     // const handleSendOtpPhone = async () => {
//     //     try {
//     //         setUpRecaptcha();
//     //         const appVerifier = window.recaptchaVerifier;
//     //         const phoneNumberWithCountryCode = '+91' + phoneNumber;

//     //         const confirmationResult = await signInWithPhoneNumber(auth, phoneNumberWithCountryCode, appVerifier);
//     //         setConfirmResult(confirmationResult);
//     //         setError('');
//     //         setOtpMessage('OTP sent to your mobile number.');
//     //     } catch (error) {
//     //         if (error.code === 'auth/too-many-requests') {
//     //             setError("Too many requests. Please try again later.");
//     //         } else {
//     //             setError("Failed to send OTP. Please try again.");
//     //         }
//     //         console.error("Error sending OTP:", error);
//     //     }
//     // };

//     // const handleSendOtpPhone = async () => {
//     //     try {
//     //         setIsOtpLoading(true); // Start showing "Sending..."
//     //         setUpRecaptcha();
//     //         const appVerifier = window.recaptchaVerifier;
//     //         const phoneNumberWithCountryCode = '+91' + phoneNumber;
    
//     //         const confirmationResult = await signInWithPhoneNumber(auth, phoneNumberWithCountryCode, appVerifier);
//     //         setConfirmResult(confirmationResult);
//     //         setError('');
//     //         setOtpMessage('OTP sent to your mobile number.');
//     //     } catch (error) {
//     //         if (error.code === 'auth/too-many-requests') {
//     //             setError("Too many requests. Please try again later.");
//     //         } else {
//     //             setError("Failed to send OTP. Please try again.");
//     //         }
//     //         console.error("Error sending OTP:", error);
//     //     } finally {
//     //         setIsOtpLoading(false); // Stop showing "Sending..." once process is complete
//     //     }
//     // };


//     const handleSendOtpPhone = async () => {
//         if (!phoneNumber || phoneNumber.length < 10) {
//             setError("Please enter a valid 10-digit phone number.");
//             return;
//         }
    
//         try {
//             setIsOtpLoading(true); // Start showing "Sending..."
//             setUpRecaptcha();
//             const appVerifier = window.recaptchaVerifier;
//             const phoneNumberWithCountryCode = '+91' + phoneNumber;
    
//             const confirmationResult = await signInWithPhoneNumber(auth, phoneNumberWithCountryCode, appVerifier);
//             setConfirmResult(confirmationResult);
//             setError('');
//             setOtpMessage('OTP sent to your mobile number.');
//         } catch (error) {
//             if (error.code === 'auth/too-many-requests') {
//                 setError("Too many requests. Please try again later.");
//             } else {
//                 setError("Failed to send OTP. Please try again.");
//             }
//             console.error("Error sending OTP:", error);
//         } finally {
//             setIsOtpLoading(false); // Stop showing "Sending..." once process is complete
//         }
//     };

//     const handleOtpChange = (e, index) => {
//         const value = e.target.value;
//         if (/^[0-9]$/.test(value) || value === '') {
//             let newOtp = [...otp];
//             newOtp[index] = value;
//             setOtp(newOtp);

//             if (value !== '' && index < 5) {
//                 document.getElementsByClassName('otp-input')[index + 1].focus();
//             }
//         }
//     };

//     if (isLoggedIn || localStorage.getItem('isLoggedIn') === 'true') {
//         // return <ChatGPT />;
//         return <ToolsPage />;
//     }

//     const handleOtpKeyDown = (e, index) => {
//         if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
//             document.getElementsByClassName('otp-input')[index - 1].focus();
//         }
//     };

//     return (
//         <div className="login-container">
//          <div className="support-button" onClick={handleSupportClick}>
//         Support
//       </div>
//             <div className="image-section">
//                 {images.map((image, index) => (
//                     <img
//                         key={index}
//                         src={image}
//                         alt="Login Carousel"
//                         className={`login-image ${index === currentImageIndex ? 'active' : ''}`}
//                         style={{ opacity: index === currentImageIndex ? 1 : 0 }}
//                     />
//                 ))}
//                 <div className="carousel-indicators">
//                     {images.map((_, index) => (
//                         <span
//                             key={index}
//                             className={`indicator ${index === currentImageIndex ? 'active' : ''}`}
//                             onClick={() => setCurrentImageIndex(index)}
//                         ></span>
//                     ))}
//                 </div>
//             </div>
//             <div className="login-section">
//                 <h1>Welcome to LexiAI</h1>
//                 <p className="subheading">
//                     Seamlessly Draft Legal Documents And Agreements With Our Intuitive Drafting Chatbot, Tailored To Your Specific Needs.
//                 </p>
//                 <form className="login-form" onSubmit={handleLogin}>
//                     {loginMode === 'email' && (
//                         <>
//                             <div className="input-group">
//                                 <input
//                                     type="email"
//                                     placeholder="Email"
//                                     className="input-field"
//                                     value={email}
//                                     onChange={(e) => setEmail(e.target.value)}
//                                     required
//                                 />
//                                 <button className="sendotp-button" id="send-email-otp-button" type="button" onClick={handleSendOtpEmail}>Verify Email</button>
//                             </div>
//                         </>
//                     )}
//                     {loginMode === 'phone' && (
//                         <>
//                             <div className="input-group">
//                                 <div className="input-mobile" style={{ display: 'flex', alignItems: 'center', position: 'relative', gap: 15 }}>
//                                     <p>+91</p>
//                                     <input
//                                         type="text"
//                                         style={{ border: 'none', fontSize: '1rem', outline: 'none', width: '100%' }}
//                                         className="input-mobilenumber"
//                                         placeholder="Phone Number"
//                                         value={phoneNumber}
//                                         onChange={(e) => setPhoneNumber(e.target.value)}
//                                         maxLength="10"
//                                         required
//                                     />
//                                     {/* <button className="sendotp-button" id="send-otp-button" type="button" onClick={handleSendOtpPhone}>Send OTP</button> */}
//                                     <button 
//                                         className="sendotp-button" 
//                                         id="send-otp-button" 
//                                         type="button" 
//                                         onClick={handleSendOtpPhone} 
//                                         disabled={isOtpLoading}
//                                         >
//                                         {isOtpLoading ? 'Sending...' : 'Send OTP'}
//                                     </button>
//                                     <div id="recaptcha-container"></div>
//                                 </div>
//                             </div>
//                             {otpMessage && <p className="otp-message">{otpMessage}</p>}
//                             <div className="otp-input-group">
//                                 {otp.map((digit, index) => (
//                                     <input
//                                         key={index}
//                                         type="text"
//                                         maxLength="1"
//                                         className="otp-input"
//                                         value={digit}
//                                         onChange={(e) => handleOtpChange(e, index)}
//                                         onKeyDown={(e) => handleOtpKeyDown(e, index)}
//                                         required
//                                     />
//                                 ))}
//                             </div>
//                         </>
//                     )}
//                     {/* {error && <p className="error-message">{error}</p>} */}
//                     {isSubscriptionChecked && error && <p className="error-message">{error}</p>}
//                     <div id="recaptcha-container"></div>
//                     <button type="submit" className={`login-button ${isLoading ? 'loading' : ''}`}>
//                         {isLoading ? <span className="tick-animation">&#10003;</span> : 'Login'}
//                     </button>
//                     {/* <p className="login-switch" onClick={() => setLoginMode(loginMode === 'email' ? 'phone' : 'email')}>
//                         {loginMode === 'email' ? 'Login with Phone Number' : 'Login with Email'}
//                     </p> */}
//                 </form>
//             </div>
//         </div>
//     );
// };

// export default LoginPage;





import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebaseConfig';
import { onAuthStateChanged, RecaptchaVerifier, signInWithPhoneNumber, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signOut } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';  // To generate unique session tokens
import './LoginPage.css';
import ToolsPage from '../Tools/ToolsPage/ToolsPage';
import loginIcon1 from '../assets/login1.jpg';
import loginIcon2 from '../assets/login2.jpg';
import loginIcon3 from '../assets/login3.jpg';
import ChatGPT from '../component/chatgpt';
import { signInWithCustomToken } from 'firebase/auth';

const LoginPage = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [loginIcon1, loginIcon2, loginIcon3];

    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [confirmResult, setConfirmResult] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [error, setError] = useState('');
    const [otpMessage, setOtpMessage] = useState('');
    const [loginMode, setLoginMode] = useState('phone');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sessionToken, setSessionToken] = useState(null);
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(false);

    useEffect(() => {
        // Extract token from URL if present
        const urlParams = new URLSearchParams(window.location.search);
        const tokenFromUrl = urlParams.get('token');
    
        if (tokenFromUrl) {
            const existingToken = localStorage.getItem('customToken');
            if (!existingToken || existingToken !== tokenFromUrl) {
                localStorage.setItem('customToken', tokenFromUrl);
            }
    
            const authenticateWithToken = async () => {
                try {
                    const result = await signInWithCustomToken(auth, tokenFromUrl);
                    if (result.user) {
                        const uid = result.user.uid;
                        await handleSingleSessionLogin(uid); // Ensure single session
                        console.log('Logged in successfully with custom token from URL');
                        setIsLoggedIn(true);
                        localStorage.setItem('isLoggedIn', 'true');

                        // Hide the custom token from the URL
                        const newUrl = window.location.origin + window.location.pathname;
                        window.history.replaceState(null, '', newUrl);
                    }
                } catch (error) {
                    console.error('Error logging in with custom token from URL:', error);
                    setError('Invalid or expired custom token. Please try again.');
                    localStorage.removeItem('customToken'); // Clear invalid token
                }
            };
    
            authenticateWithToken();
        }
    }, []);
    

    useEffect(() => {
        const cachedToken = localStorage.getItem('customToken');
        if (cachedToken) {
            const authenticateWithCachedToken = async () => {
                try {
                    const result = await signInWithCustomToken(auth, cachedToken);
                    if (result.user) {
                        const uid = result.user.uid;
                        await handleSingleSessionLogin(uid); // Ensure single session
                        console.log('Auto-logged in with cached custom token');
                        setIsLoggedIn(true);
                        localStorage.setItem('isLoggedIn', 'true');

                        // Hide the custom token from the URL
                        const newUrl = window.location.origin + window.location.pathname;
                        window.history.replaceState(null, '', newUrl);
                    }
                } catch (error) {
                    console.error('Error auto-logging in with cached token:', error);
                    localStorage.removeItem('customToken'); // Clear invalid token
                }
            };
    
            authenticateWithCachedToken();
        }
    }, []);
    
    


    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [images.length]);



      // Support button click handler to open WhatsApp
  const handleSupportClick = () => {
    const phoneNumber = '7200399562';
    const url = `https://wa.me/+91${phoneNumber}`;
    window.open(url, '_blank');
  };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                checkSubscription(uid);
            }
        });

        if (isSignInWithEmailLink(auth, window.location.href)) {
            let emailForSignIn = window.localStorage.getItem('emailForSignIn') || '';
            if (!emailForSignIn) {
                emailForSignIn = window.prompt('Please provide your email for confirmation');
            }
            if (emailForSignIn) {
                signInWithEmailLink(auth, emailForSignIn, window.location.href)
                    .then((result) => {
                        if (result.user) {
                            const uid = result.user.uid;
                            checkSubscription(uid);
                        }
                    })
                    .catch((error) => {
                        setError("Error signing in with email link. Please try again.");
                        console.error("Error signing in with email link:", error);
                    });
            }
        }

        const subscriptionExpired = localStorage.getItem('subscriptionExpired');
        if (subscriptionExpired) {
            setError("Your subscription has expired. Please renew to continue.");
            localStorage.removeItem('subscriptionExpired');
        }

        return () => unsubscribe();
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
    
        try {
            if (loginMode === 'custom') {
                // Retrieve the token from localStorage if available
                const cachedToken = localStorage.getItem('customToken') || sessionToken;
    
                if (cachedToken) {
                    const result = await signInWithCustomToken(auth, cachedToken);
                    if (result.user) {
                        const uid = result.user.uid;
                        await handleSingleSessionLogin(uid); // Ensure single session
                        console.log('Logged in successfully with custom token');
                        setIsLoggedIn(true);
                        localStorage.setItem('isLoggedIn', 'true');
                    }
                } else {
                    setError('Custom token is required to log in.');
                }
            } else if (loginMode === 'email') {
                // Handle email login
            } else if (loginMode === 'phone') {
                // Handle phone login
            }
        } catch (error) {
            setError('Invalid custom token or authentication failed.');
            console.error('Error authenticating with custom token:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    
    // Function to handle single session login
    const handleSingleSessionLogin = async (uid) => {
        const newSessionToken = uuidv4(); // Generate a unique session token
        const userRef = doc(db, "users", uid);
    
        try {
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                const storedSessionToken = userData.sessionToken;
    
                // Update session token in Firestore and cache
                await updateDoc(userRef, { sessionToken: newSessionToken });
                localStorage.setItem('sessionToken', newSessionToken);
                console.log('Session token updated');
            } else {
                // If user does not exist in Firestore, create a new document with the session token
                await setDoc(userRef, { sessionToken: newSessionToken });
                localStorage.setItem('sessionToken', newSessionToken);
                console.log('Session token created');
            }
        } catch (error) {
            console.error("Error handling single session:", error);
            throw error;
        }
    };
    
    
      
    // const checkSubscription = async (uid) => {
    //     try {
    //         const userDoc = await getDoc(doc(db, "users", uid));
    //         if (userDoc.exists()) {
    //             const userData = userDoc.data();
    //             if (userData.isSubscribed) {
    //                 const subscriptionEndDate = new Date(userData.subscriptionEndDate.seconds * 1000);
    //                 const currentDate = new Date();

    //                 if (subscriptionEndDate > currentDate) {
    //                     setIsLoading(false);
    //                     setTimeout(() => {
    //                         setIsLoggedIn(true);
    //                         localStorage.setItem('isLoggedIn', 'true');
    //                     }, 1000);
    //                 } else {
    //                     setError("Your subscription has expired. Please renew to continue.");
    //                     setIsLoading(false);
    //                 }
    //             } else {
    //                 setError("You are not subscribed to use this service.");
    //                 setIsLoading(false);
    //             }
    //         } else {
    //             setError("User data not found.");
    //             setIsLoading(false);
    //         }
    //     } catch (error) {
    //         console.error("Error checking subscription:", error);
    //         setError("Error checking subscription status. Please try again.");
    //         setIsLoading(false);
    //     }
    // };

    // Update checkSubscription to set the subscription check state
    const checkSubscription = async (uid) => {
        try {
            const userDoc = await getDoc(doc(db, "users", uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.isSubscribed) {
                    const subscriptionEndDate = new Date(userData.subscriptionEndDate.seconds * 1000);
                    const currentDate = new Date();

                    if (subscriptionEndDate > currentDate) {
                        setTimeout(() => {
                            setIsLoggedIn(true);
                            localStorage.setItem('isLoggedIn', 'true');
                        }, 1000);
                    } else {
                        setError("Your subscription has expired. Please renew to continue.");
                    }
                } else {
                    setError("You are not subscribed to use this service.");
                }
            } else {
                setError("User data not found.");
            }
        } catch (error) {
            console.error("Error checking subscription:", error);
            setError("Error checking subscription status. Please try again.");
        } finally {
            setIsSubscriptionChecked(true); // Mark subscription check as complete
            setIsLoading(false);
        }
    };

    const handleSendOtpEmail = async () => {
        try {
            const actionCodeSettings = {
                url: window.location.href,
                handleCodeInApp: true,
            };
            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
            window.localStorage.setItem('emailForSignIn', email);
            setIsOtpSent(true);
            setError('Email sent! Please check your inbox.');
        } catch (error) {
            setError('Failed to send email OTP. Please try again.');
            console.error('Error sending email OTP:', error);
        }
    };

    const setUpRecaptcha = () => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                auth,
                'recaptcha-container',
                {
                    size: 'invisible',
                    callback: (response) => {
                        console.log('reCAPTCHA solved.');
                    },
                    'expired-callback': () => {
                        console.log('reCAPTCHA expired; please try again.');
                    },
                }
            );
        }
    };

    // const handleSendOtpPhone = async () => {
    //     try {
    //         setUpRecaptcha();
    //         const appVerifier = window.recaptchaVerifier;
    //         const phoneNumberWithCountryCode = '+91' + phoneNumber;

    //         const confirmationResult = await signInWithPhoneNumber(auth, phoneNumberWithCountryCode, appVerifier);
    //         setConfirmResult(confirmationResult);
    //         setError('');
    //         setOtpMessage('OTP sent to your mobile number.');
    //     } catch (error) {
    //         if (error.code === 'auth/too-many-requests') {
    //             setError("Too many requests. Please try again later.");
    //         } else {
    //             setError("Failed to send OTP. Please try again.");
    //         }
    //         console.error("Error sending OTP:", error);
    //     }
    // };

    // const handleSendOtpPhone = async () => {
    //     try {
    //         setIsOtpLoading(true); // Start showing "Sending..."
    //         setUpRecaptcha();
    //         const appVerifier = window.recaptchaVerifier;
    //         const phoneNumberWithCountryCode = '+91' + phoneNumber;
    
    //         const confirmationResult = await signInWithPhoneNumber(auth, phoneNumberWithCountryCode, appVerifier);
    //         setConfirmResult(confirmationResult);
    //         setError('');
    //         setOtpMessage('OTP sent to your mobile number.');
    //     } catch (error) {
    //         if (error.code === 'auth/too-many-requests') {
    //             setError("Too many requests. Please try again later.");
    //         } else {
    //             setError("Failed to send OTP. Please try again.");
    //         }
    //         console.error("Error sending OTP:", error);
    //     } finally {
    //         setIsOtpLoading(false); // Stop showing "Sending..." once process is complete
    //     }
    // };


    const handleSendOtpPhone = async () => {
        if (!phoneNumber || phoneNumber.length < 10) {
            setError("Please enter a valid 10-digit phone number.");
            return;
        }
    
        try {
            setIsOtpLoading(true); // Start showing "Sending..."
            setUpRecaptcha();
            const appVerifier = window.recaptchaVerifier;
            const phoneNumberWithCountryCode = '+91' + phoneNumber;
    
            const confirmationResult = await signInWithPhoneNumber(auth, phoneNumberWithCountryCode, appVerifier);
            setConfirmResult(confirmationResult);
            setError('');
            setOtpMessage('OTP sent to your mobile number.');
        } catch (error) {
            if (error.code === 'auth/too-many-requests') {
                setError("Too many requests. Please try again later.");
            } else {
                setError("Failed to send OTP. Please try again.");
            }
            console.error("Error sending OTP:", error);
        } finally {
            setIsOtpLoading(false); // Stop showing "Sending..." once process is complete
        }
    };

    const handleOtpChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === '') {
            let newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value !== '' && index < 5) {
                document.getElementsByClassName('otp-input')[index + 1].focus();
            }
        }
    };

    if (isLoggedIn || localStorage.getItem('isLoggedIn') === 'true') {
        return <ToolsPage />;
    }

    const handleOtpKeyDown = (e, index) => {
        if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
            document.getElementsByClassName('otp-input')[index - 1].focus();
        }
    };

    return (
        <div className="login-container">
         <div className="support-button" onClick={handleSupportClick}>
        Support
      </div>
      <p className='verify-text'>Please wait while verifying...</p>
            <div className="image-section">
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt="Login Carousel"
                        className={`login-image ${index === currentImageIndex ? 'active' : ''}`}
                        style={{ opacity: index === currentImageIndex ? 1 : 0 }}
                    />
                ))}
            
                <div className="carousel-indicators">
                    {images.map((_, index) => (
                        <span
                            key={index}
                            className={`indicator ${index === currentImageIndex ? 'active' : ''}`}
                            onClick={() => setCurrentImageIndex(index)}
                        ></span>
                    ))}
                </div>
            </div>
            
        </div>
    );
};

export default LoginPage;
