import React, { useState, useRef, useEffect } from 'react';
import { auth, db, storage } from '../../firebaseConfig'; // Firebase setup
import { doc, getDoc, updateDoc, addDoc, collection, query, where, getDocs } from 'firebase/firestore'; // Firestore imports
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase storage imports
import './CompressPdf.css'; // CSS file
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png'

const CompressPdf = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [compressedFileUrl, setCompressedFileUrl] = useState(null);
  const [isCompressing, setIsCompressing] = useState(false);
  const [compressionLevel, setCompressionLevel] = useState('recommended'); // Default to recommended compression
  const [errorMessage, setErrorMessage] = useState(null);
  const [toolPrice, setToolPrice] = useState(null); // Tool price from Firestore
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [confirmationMessage, setConfirmationMessage] = useState(''); // Confirmation message
  const [isCopied, setIsCopied] = useState(false); // Copy status
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation

  const PUBLIC_KEY = 'project_public_89bba9932e7a274620c5d22eb4ce981a_9dOWN7a1ea85b9a31ff5f2d6d0d3924dc594b';
  const AUTH_API_URL = 'https://api.ilovepdf.com/v1/auth';

  useEffect(() => {
    // Fetch tool price from Firestore for PDF compression
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Compress PDF'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0]; // Assuming only one document matches
          setToolPrice(toolDoc.data().price); // Set the tool price from Firestore
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  const handleFileSelection = (event) => {
    setSelectedFile(event.target.files[0]);
    setCompressedFileUrl(null);
    setErrorMessage(null);
  };

  const handleCompressionLevelChange = (event) => {
    setCompressionLevel(event.target.value);
  };

  const handleCompress = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a PDF file to compress.');
      return;
    }
  
    if (!toolPrice) {
      setErrorMessage('Tool price not available. Please try again later.');
      return;
    }
  
    setErrorMessage(null);
  
    try {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
  
        // Fetch user balance
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // const currentBalance = userData.balance || 0;
          const currentBalance = userData.pdfCredits || 0;
  
          if (currentBalance < toolPrice) {
            setErrorMessage(`Insufficient credits.`);
            return; // Stop the process if balance is insufficient
          }
  
          // Show confirmation modal if balance is sufficient
          setIsModalOpen(true);
        } else {
          setErrorMessage('User details not found.');
        }
      } else {
        setErrorMessage('User not logged in. Please log in.');
      }
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred while checking wallet balance.');
    }
  };
  

  const confirmCompression = async () => {
    setIsModalOpen(false); // Close modal
    setIsCompressing(true); // Show compressing state

    try {
      const token = await getToken();
      const { server, task } = await startTask(token, 'compress');

      const serverFilename = await uploadFile(server, task, selectedFile, token);

      await processFiles(server, task, serverFilename, selectedFile.name, compressionLevel, token);

      const compressedBlob = await downloadFile(server, task, token);
      const downloadUrl = await uploadCompressedPdfToFirebase(compressedBlob); // Upload to Firebase

      setCompressedFileUrl(downloadUrl); // Set download URL for sharing
      setConfirmationMessage(`${toolPrice} Credits have been deducted from your wallet.`);
      await deductAmountFromWallet(auth.currentUser.uid, toolPrice);
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred while compressing the PDF. Please try again later.');
    } finally {
      setIsCompressing(false);
    }
  };

  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      // const currentBalance = userData.balance || 0;
          const currentBalance = userData.pdfCredits || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        // await updateDoc(userDocRef, { balance: newBalance });
        await updateDoc(userDocRef, { pdfCredits: newBalance });
      } else {
        throw new Error('Insufficient credits.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  const uploadCompressedPdfToFirebase = async (blob) => {
    try {
      const fileName = `compressed_pdf_${Date.now()}.pdf`; // Unique filename with timestamp
      const storageRef = ref(storage, `compressedPdfs/${fileName}`);
      const snapshot = await uploadBytes(storageRef, blob);
      const downloadUrl = await getDownloadURL(snapshot.ref);
      await addDoc(collection(db, 'compressedPdfs'), { downloadUrl, timestamp: new Date() });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload compressed PDF to Firebase.');
    }
  };

  const getToken = async () => {
    const response = await fetch(AUTH_API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ public_key: PUBLIC_KEY })
    });
    const data = await response.json();
    return data.token;
  };

  const startTask = async (token, tool) => {
    const response = await fetch(`https://api.ilovepdf.com/v1/start/${tool}`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    });
    const data = await response.json();
    return data;
  };

  const uploadFile = async (server, task, file, token) => {
    const formData = new FormData();
    formData.append('task', task);
    formData.append('file', file);

    const response = await fetch(`https://${server}/v1/upload`, {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${token}` },
      body: formData
    });

    const data = await response.json();
    return data.server_filename;
  };

  const processFiles = async (server, task, serverFilename, originalFilename, compressionLevel, token) => {
    const response = await fetch(`https://${server}/v1/process`, {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        task, tool: 'compress', files: [{ server_filename: serverFilename, filename: originalFilename }],
        compression_level: compressionLevel
      })
    });
    return await response.json();
  };

  const downloadFile = async (server, task, token) => {
    const response = await fetch(`https://${server}/v1/download/${task}`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` },
    });

    const blob = await response.blob();
    return blob;
  };

  const copyLinkToClipboard = async () => {
    if (!compressedFileUrl) {
      setConfirmationMessage('No link to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(compressedFileUrl);
      setIsCopied(true);
      setConfirmationMessage('PDF Link copied to clipboard. paste copied link in any browser to download pdf file.');
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className="compress-pdf-container">
      <h1>Compress PDF files</h1>
      <p>Reduce the size of your PDF file without losing quality.</p>

      <div className="file-upload-container">
        <input 
          type="file" 
          accept='.pdf'
          onChange={handleFileSelection} 
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFile ? 'none' : 'block' }}
        >
          Select PDF file
        </button>
      </div>

      {selectedFile && (
        <div className="pdf-preview-container" style={{flexDirection: 'column'}}>
          <div className="pdf-preview">
            <div className="pdf-thumbnail">
              <span>FileName: {selectedFile.name}</span>
              <span>FileSize: {(selectedFile.size / 1024 / 1024).toFixed(2)} MB</span>
            </div>
          </div>

          <div className="compression-level-container">
            <label htmlFor="compression-level">Compression Level:</label>
            <select 
              id="compression-level" 
              value={compressionLevel} 
              onChange={handleCompressionLevelChange}
            >
              <option value="extreme">Extreme</option>
              <option value="recommended">Recommended</option>
              <option value="low">Low</option>
            </select>
          </div>

          <button 
            className="compress-button" 
            onClick={handleCompress}
            disabled={isCompressing}
          >
            {isCompressing ? 'Compressing...' : 'Compress PDF'}
          </button>

          {compressedFileUrl && (
            <div className="share-options">
              <button className="share-button" onClick={copyLinkToClipboard}>
                {isCopied ? 'Copied!' : 'Copy Link'}
              </button>
            </div>
          )}

          
        </div>
      )}

      {confirmationMessage && <div className="confirmation-message" style={{marginTop: '25px'}}>{confirmationMessage}</div>}

      {errorMessage && <div className="error-message" style={{marginTop: '25px'}}>{errorMessage}</div>}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to deduct {toolPrice} credits for compression?</p>
            <div className="modal-buttons">
              <button className="modal-confirm" onClick={confirmCompression}>OK</button>
              <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default CompressPdf;
