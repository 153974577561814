import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { storage, db, auth } from '../../firebaseConfig'; // Firebase setup
import './SplitPdf.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png'

const SplitPdf = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSplitting, setIsSplitting] = useState(false);
  const [splitCriteria, setSplitCriteria] = useState('');
  const [splitFileUrl, setSplitFileUrl] = useState(null);
  const [toolPrice, setToolPrice] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation

  const PUBLIC_KEY = 'project_public_89bba9932e7a274620c5d22eb4ce981a_9dOWN7a1ea85b9a31ff5f2d6d0d3924dc594b'; 
  const AUTH_API_URL = 'https://api.ilovepdf.com/v1/auth';

  useEffect(() => {
    // Fetch tool price from Firestore
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Split PDF'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price); // Set tool price from Firestore
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  const handleFileSelection = (event) => {
    setSelectedFile(event.target.files[0]);
    setErrorMessage(null);
    setSplitFileUrl(null); // Reset the split file URL
    setIsCopied(false); // Reset the copy status
  };

  const handleSplitCriteriaChange = (event) => {
    setSplitCriteria(event.target.value);
  };

  const confirmProcess = async () => {
    try {
      setIsModalOpen(false); // Close the modal initially
  
      // Fetch user balance
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        // const currentBalance = userData.balance || 0;
          const currentBalance = userData.pdfCredits || 0;
  
        if (currentBalance < toolPrice) {
          setErrorMessage('Insufficient credits.');
          return; // Stop the process if the user doesn't have enough balance
        }
  
        setIsModalOpen(true); // Open confirmation modal if sufficient balance
      } else {
        setErrorMessage('User not found.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while checking wallet balance.');
    }
  };

  // const handleSplit = async () => {
  //   if (!selectedFile) {
  //     setErrorMessage('Please select a PDF file to split.');
  //     return;
  //   }

  //   if (!splitCriteria) {
  //     setErrorMessage('Please specify the split criteria (e.g., 1-3,5).');
  //     return;
  //   }

  //   setIsSplitting(true);
  //   setErrorMessage(null);
  //   setIsModalOpen(false); // Close modal

  //   try {
  //     const token = await getToken();
  //     const { server, task } = await startTask(token, 'split');

  //     const serverFilename = await uploadFile(server, task, selectedFile, token);

  //     const zipBlob = await processFiles(server, task, serverFilename, selectedFile.name, splitCriteria, token);
  //     const downloadUrl = await uploadZipToFirebase(zipBlob); // Upload the zip file to Firebase

  //     setSplitFileUrl(downloadUrl); // Set the download URL for display

  //     setConfirmationMessage(`${toolPrice} Credits have been deducted from your wallet.`);
  //     await deductAmountFromWallet(auth.currentUser.uid, toolPrice); // Deduct wallet amount
  //   } catch (error) {
  //     setErrorMessage(error.message || 'An error occurred while splitting the PDF. Please try again later.');
  //   } finally {
  //     setIsSplitting(false);
  //   }
  // };

  const handleSplit = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a PDF file to split.');
      return;
    }
  
    if (!splitCriteria || !splitCriteria.includes(',')) {
      setErrorMessage('Please enter two page numbers followed by a comma to start the process.');
      setIsModalOpen(false); // Close the modal if criteria are not met
      return; // Return early to prevent further processing and coin deduction
    }
  
    setIsSplitting(true);
    setErrorMessage(null);
    setIsModalOpen(false); // Close modal only after passing validation
  
    try {
      const token = await getToken();
      const { server, task } = await startTask(token, 'split');
  
      const serverFilename = await uploadFile(server, task, selectedFile, token);
  
      const zipBlob = await processFiles(server, task, serverFilename, selectedFile.name, splitCriteria, token);
      const downloadUrl = await uploadZipToFirebase(zipBlob); // Upload the zip file to Firebase
  
      setSplitFileUrl(downloadUrl); // Set the download URL for display
  
      setConfirmationMessage(`${toolPrice} Credits have been deducted from your wallet.`);
      await deductAmountFromWallet(auth.currentUser.uid, toolPrice); // Deduct wallet amount
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred while splitting the PDF. Please try again later.');
    } finally {
      setIsSplitting(false);
    }
  };
  
  

  const resetPage = () => {
    setSelectedFile(null);
    setIsSplitting(false);
    setSplitCriteria('');
    setErrorMessage(null);
    setConfirmationMessage('');
    setIsCopied(false); // Reset copy status

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const getToken = async () => {
    const response = await fetch(AUTH_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_key: PUBLIC_KEY })
    });

    const data = await response.json();
    return data.token;
  };

  const startTask = async (token, tool) => {
    const response = await fetch(`https://api.ilovepdf.com/v1/start/${tool}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const text = await response.text();
    if (!response.ok || !text) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = JSON.parse(text);
    return data;
  };

  const uploadFile = async (server, task, file, token) => {
    const formData = new FormData();
    formData.append('task', task);
    formData.append('file', file);

    const response = await fetch(`https://${server}/v1/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`Upload failed with status ${response.status}`);
    }

    const data = await response.json();
    return data.server_filename;
  };

  const processFiles = async (server, task, serverFilename, originalFilename, splitCriteria, token) => {
    const response = await fetch(`https://${server}/v1/process`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        task: task,
        tool: 'split',
        files: [
          {
            server_filename: serverFilename,
            filename: originalFilename
          }
        ],
        split_mode: 'ranges', 
        ranges: splitCriteria, 
        merge_after: false,
        packaged_filename: `split_${originalFilename.replace('.pdf', '')}.zip`
      })
    });
  
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Process failed with status ${response.status}`);
    }
  
    return await downloadFiles(server, task, token); // Get zip blob
  };

  const downloadFiles = async (server, task, token) => {
    const response = await fetch(`https://${server}/v1/download/${task}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Download failed with status ${response.status}`);
    }

    const blob = await response.blob();
    return blob; // Return the zip blob
  };

  const uploadZipToFirebase = async (zipBlob) => {
    try {
      const fileName = `split_pdf_${Date.now()}.zip`;
      const storageRef = ref(storage, `splitPdfs/${fileName}`);
      const snapshot = await uploadBytes(storageRef, zipBlob); // Upload the zip file to Firebase Storage
      const downloadUrl = await getDownloadURL(snapshot.ref); // Get the file's download URL

      // Store download URL in Firestore
      await addDoc(collection(db, 'splitPdfs'), {
        downloadUrl,
        timestamp: new Date(),
      });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload split zip to Firebase.');
    }
  };

  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      // const currentBalance = userData.balance || 0;
          const currentBalance = userData.pdfCredits || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        // await updateDoc(userDocRef, { balance: newBalance });
        await updateDoc(userDocRef, { pdfCredits: newBalance });
      } else {
        throw new Error('Insufficient credits.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  const copyLinkToClipboard = async () => {
    if (!splitFileUrl) {
      setConfirmationMessage('No link to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(splitFileUrl);
      setIsCopied(true); // Set to true when the link is copied
      setConfirmationMessage('Split PDF link copied to clipboard. Paste the copied link in any browser to download the file.');
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };

  return (
    <>
     <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className="split-pdf-container">
      <h1>Split PDF</h1>
      <p>Split a PDF into multiple files based on your criteria.</p>
      <p style={{ color: 'red', marginTop: '30px' }} className="noteStt">
            Note: please enter atleast two page numbers followed by comma like this (1-2,4-5) or (3,7-9)
      </p>

      <div className="file-upload-container">
        <input 
          type="file" 
          accept='.pdf'
          onChange={handleFileSelection} 
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFile ? 'none' : 'block' }}
        >
          Select PDF file
        </button>
      </div>

      {selectedFile && (
        <div className="split-pdf-inputs">
          <div className="pdf-preview">
              <div className="pdf-thumbnail">
                <span>FileName: {selectedFile.name}</span>
                <span>FileSize: {(selectedFile.size / 1024 / 1024).toFixed(2)} MB</span>
              </div>
            </div>

          <input
            type="text"
            placeholder="Enter split criteria (e.g., 1-3,5-7 or 3,4)"
            value={splitCriteria}
            onChange={handleSplitCriteriaChange}
            className="split-criteria-input"
          />

          

          <button 
            className="crop-button" 
            onClick={confirmProcess}
            disabled={isSplitting}
          >
            {isSplitting ? 'Splitting...' : 'Split PDF'}
          </button>
        </div>
      )}

      {splitFileUrl && (
        <div className="share-options">
          <button className="share-button" onClick={copyLinkToClipboard}>
            {isCopied ? 'Copied!' : 'Copy Link'}
          </button>
        </div>
      )}

      {errorMessage && <div className="error-message" style={{marginTop: '25px', color: 'red'}}>{errorMessage}</div>}
      {confirmationMessage && <div className="confirmation-message" style={{marginTop: '25px'}}>{confirmationMessage}</div>}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to deduct {toolPrice} credits for splitting the PDF?</p>
            <div className="modal-buttons">
              <button className="modal-confirm" onClick={handleSplit}>Ok</button>
              <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default SplitPdf;
